<template>
  <v-dialog v-model="exibirModalCadastrarContratoWebCheckin" width="1000" max-width="80vw">
      <div style="background-color: white;">
           <v-toolbar color="blue" class="mb-8">
              <v-flex  style="display: flex" class="pa-2">
                  <span style="width:95%;color:white;font-weight:bold;" >
                      {{ tituloModal }}
                  </span>
                  <span style="width:5%"> 
                      <v-btn x-small color="red" @click="() => this.$emit('setModalCadastrarContratoWebCheckin')" title="Fechar">
                          <v-icon x-small style="color:white;">mdi-close</v-icon>
                      </v-btn>
                  </span>
              </v-flex>
          </v-toolbar>
          <div style="display:flex; flex-direction: row; justify-content: space-between; width: 100%">
          </div>
          <div style="padding: 20px;">
            <p style="margin: 5px 0 20px 0">Tags de Substituição Automáticas: <span style="color: rgb(70, 70, 70); font-size: small;">@assinatura, @nome, @sobrenome, @nascimento, @sexo, @profissao, @cep, @logradouro, @numerologradouro, @estado, @cidade, @bairro, @complemento, @nacionalidade, @documento, @tipodocumentopessoa, @email, @numerotelefone, @motivoviagem, @meiodetransporte, @tipodocumentopessoa, @comonosconheceu, @modelocarro, @numeroplaca.</span></p>
              <quill-editor :options="editorOptions" v-model="content" />
          </div>
            <div style="width: 100%; display: flex; justify-content: flex-end; padding: 10px;">
              <button class="buttonCadastrar" style="margin-right: 10px;" @click="convertClassesToInlineStyles({
                enviarWebCheckin: false
              })">{{ msgBotaoCadastrarAtualizar }}</button>
              <button class="buttonCadastrar" @click="convertClassesToInlineStyles({
                enviarWebCheckin: true
              })">{{ msgBotaoCadastrarAtualizarEnviarWebCheckin }}</button>
            </div>
          </div>
  </v-dialog>
</template>  
<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { quillEditor } from 'vue-quill-editor'
    import ImageResize from 'quill-image-resize'
    import Quill from 'quill'
    Quill.register('modules/imageResize', ImageResize)
 export default {
  components: {
    quillEditor
  },

  name: 'ModalCadastrarContratoWebCheckin',
  data: () => ({
    tituloModal: 'Cadastrar Contrato',
    content: '',
    empresasReservantes: null,
    canalDeVenda: null,
    tituloContrato: null,
    html: null,
    update: false,
    msgBotaoCadastrarAtualizar: 'Salvar',
    msgBotaoCadastrarAtualizarEnviarWebCheckin: 'Salvar e Enviar Web Check-in',
    editorOptions: {
          modules: {
            toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ align: [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ],
            imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
              },
              modules: ['Resize', 'DisplaySize']
            }
          },
        },
        contratos: null
  }),

  props: ['exibirModalCadastrarContratoWebCheckin', 'dados'],

  methods: {
    teste() {
      console.log(this.content);
    },

    async cadastrarContrato(data) {

      if (!this.html || this.html.length === 0) {
        alert('É obrigatório digitar o contrato.');
        return;
      }

      const body = {
        id: this.dados.id,
        contrato: this.html,
        enviarWebCheckin: data.enviarWebCheckin,
        idpessoa: this.dados.idpessoa
      }
      if (data.enviarWebCheckin) {
        this.msgBotaoCadastrarAtualizarEnviarWebCheckin = 'Aguarde...';
      } else {
        this.msgBotaoCadastrarAtualizar = 'Aguarde...';
      }
      try {
        await this.$http.post("contrato/inserirContratoReserva", body);
        if (data.exibirAlerta) {
          alert('Contrato salvo com sucesso!')
        }
        this.msgBotaoCadastrarAtualizar = 'Salvar';
        this.msgBotaoCadastrarAtualizarEnviarWebCheckin = 'Salvar e Enviar Web Check-in'
      } catch (error) {
          this.msgBotaoCadastrarAtualizar = 'Salvar';
          this.msgBotaoCadastrarAtualizarEnviarWebCheckin = 'Salvar e Enviar Web Check-in'
        alert(error.response.data);
      }

    },

    convertClassesToInlineStyles(data) {
      const div = document.createElement('div')
      div.innerHTML = this.content

      const elements = div.querySelectorAll('[class]')

      elements.forEach(element => {
        const classes = element.classList

        for (let i = classes.length - 1; i >= 0; i--) {
          const className = classes[i]
          const inlineStyle = this.getInlineStyleForClass(className)

          if (inlineStyle) {
            element.removeAttribute('class')
            element.setAttribute('style', inlineStyle)
          }
        }
      })
      this.html = div.innerHTML
      this.cadastrarContrato({
        exibirAlerta: true,
        enviarWebCheckin: data.enviarWebCheckin
      });
      return div.innerHTML
    },
    getInlineStyleForClass(className) {
      const styles = {
        'ql-indent-8': 'margin-left: 2rem;',
        'ql-size-small': 'font-size: 14px;',
        'ql-size-large': 'font-size: 20px;',
        'ql-size-huge': 'font-size: 30px;',
        'ql-align-center': 'text-align: center;',
        'ql-align-right': 'text-align: right;',
        'ql-font-serif': 'font-family: serif;',
        'ql-font-monospace': 'font-family: monospace;'
      }

      return styles[className] || ''
    },

    async buscarEmpresasReservantes() {
      try {
        const result = await this.$http.post("/contrato/selecionarEmpresaReservante");
        console.log(result.data)
        this.empresasReservantes= result.data;
      } catch (error) {
        console.log(error);
      }
    },

    async buscarContratos() {
      try {
        const result = await this.$http.post('/contrato/buscarContratos');
        console.log(result.data)
        this.contratos = result.data;
      } catch (error) {
        console.log('erro ao buscar contratos');
      }
    },
  },

  async mounted() {
    console.log('dados>>>>>>>>>>', this.dados)
    try {
      let result = await this.$http.post("/contrato/buscarcontratoreserva", {
        id: this.dados.id
      });
      console.log('result', result.data)
      if (result.data[0] && !result.data[0].contrato) {
        console.log('entrou aqui sem contrato')
        await this.buscarContratos();
        let selecionarContratoEmpresaReservante = []
        if (this.contratos && this.contratos.length) {
           selecionarContratoEmpresaReservante = this.contratos.filter((e) => e.empresareservante.toLowerCase() === this.dados.empresareservante.toLowerCase())
        }
        if (selecionarContratoEmpresaReservante.length) {
          this.html = selecionarContratoEmpresaReservante[0].contrato;
          await this.cadastrarContrato({
            exibirAlerta: false,
            enviarWebCheckin: false,
          });
          result = await this.$http.post("/contrato/buscarcontratoreserva", {
            id: this.dados.id
          });
        }
      }
      this.content = result?.data[0]?.contrato;
      this.update = false;
      this.msgBotaoCadastrarAtualizar = 'Salvar';
      this.tituloModal = 'Cadastrar Contrato'
    } catch (error) {
      alert('Erro ao exibir contrato, tente novamente mais tarde.')
    }    
  }
 }
</script>

<style scoped>
.buttonCadastrar {
  background-color: #1D4596;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0 0 0;
}
</style>