<template>
  <v-dialog v-model="exibirModalCadastrarContratoWebCheckin" width="1000" max-width="80vw">
      <div style="background-color: white;">
           <v-toolbar color="blue" class="mb-8">
              <v-flex  style="display: flex" class="pa-2">
                  <span style="width:95%;color:white;font-weight:bold;" >
                      {{ tituloModal }}
                  </span>
                  <span style="width:5%"> 
                      <v-btn x-small color="red" @click="() => this.$emit('setModalCadastrarContratoWebCheckin')" title="Fechar">
                          <v-icon x-small style="color:white;">mdi-close</v-icon>
                      </v-btn>
                  </span>
              </v-flex>
          </v-toolbar>
          <div style="display:flex; flex-direction: row; justify-content: space-between; width: 100%; padding: 10px;">
            <v-text-field label="Título do contrato" v-model="tituloContrato" style="width: 50%; margin-right: 50px;" outlined></v-text-field>
            <v-select v-model="canalDeVenda" style="width: 50%;" :items="empresasReservantes" item-text="empresareservante" item-value="idempresareservante" label="Canais de venda" return-object outlined></v-select>
          </div>
          <div style="padding: 20px;">
            <p style="margin: 5px 0 20px 0">Tags de Substituição Automáticas: <span style="color: rgb(70, 70, 70); font-size: small;">@assinatura, @nome, @sobrenome, @nascimento, @sexo, @profissao, @cep, @logradouro, @numerologradouro, @estado, @cidade, @bairro, @complemento, @nacionalidade, @documento, @tipodocumentopessoa, @email, @numerotelefone, @motivoviagem, @meiodetransporte, @tipodocumentopessoa, @comonosconheceu, @modelocarro, @numeroplaca.</span></p>
              <quill-editor v-model="content" />
          </div>
            <div style="width: 100%; display: flex; justify-content: flex-end; padding: 10px;">
              <button class="buttonCadastrar" @click="convertClassesToInlineStyles">{{ msgBotaoCadastrarAtualizar }}</button>
            </div>
          </div>
  </v-dialog>
</template>  
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
 export default {
  components: {
    quillEditor
  },

  name: 'ModalCadastrarContratoWebCheckin',
  data: () => ({
    tituloModal: 'Cadastrar Contrato',
    content: '',
    empresasReservantes: null,
    canalDeVenda: null,
    tituloContrato: null,
    html: null,
    update: false,
    msgBotaoCadastrarAtualizar: 'Cadastrar Contrato'
  }),

  props: ['exibirModalCadastrarContratoWebCheckin', 'dados'],

  methods: {
    teste() {
      console.log(this.content);
    },

    async cadastrarContrato() {
      if (!this.tituloContrato || this.tituloContrato.length === 0) {
        alert('É obrigatório informar um título para o contrato.');
        return;
      }

      if (!this.canalDeVenda) {
        alert('É obrigatório informar um canal de venda para o contrato.');
        return;
      }

      if (!this.html || this.html.length === 0) {
        alert('É obrigatório digitar o contrato.');
        return;
      }

      const body = {
        id: this.dados && this.dados.id ? this.dados.id : null,
        titulo: this.tituloContrato,
        contrato: this.html,
        idempresareservante: this.canalDeVenda.idempresareservante,
        update: this.update,
      }

      console.log(body);
      this.msgBotaoCadastrarAtualizar = 'Aguarde...';
      try {
        await this.$http.post("contrato/cadastrar", body);
        this.$emit('buscarContratosAtualizados');
        this.$emit('setModalCadastrarContratoWebCheckin');
      } catch (error) {
        if (this.update) {
          this.msgBotaoCadastrarAtualizar = 'Atualizar Contrato';
        } else {
          this.msgBotaoCadastrarAtualizar = 'Cadastrar Contrato';
        }
        alert(error.response.data);
      }

    },

    convertClassesToInlineStyles() {
      const div = document.createElement('div')
      div.innerHTML = this.content

      const elements = div.querySelectorAll('[class]')

      elements.forEach(element => {
        const classes = element.classList

        for (let i = classes.length - 1; i >= 0; i--) {
          const className = classes[i]
          const inlineStyle = this.getInlineStyleForClass(className)

          if (inlineStyle) {
            element.removeAttribute('class')
            element.setAttribute('style', inlineStyle)
          }
        }
      })
      this.html = div.innerHTML
      this.cadastrarContrato();
      return div.innerHTML
    },
    getInlineStyleForClass(className) {
      const styles = {
        'ql-indent-8': 'margin-left: 2rem;',
        'ql-size-small': 'font-size: 14px;',
        'ql-size-large': 'font-size: 20px;',
        'ql-size-huge': 'font-size: 30px;',
        'ql-align-center': 'text-align: center;',
        'ql-align-right': 'text-align: right;',
        'ql-font-serif': 'font-family: serif;',
        'ql-font-monospace': 'font-family: monospace;'
      }

      return styles[className] || ''
    },

    async buscarEmpresasReservantes() {
      try {
        const result = await this.$http.post("/contrato/selecionarEmpresaReservante");
        console.log(result.data)
        this.empresasReservantes= result.data;
      } catch (error) {
        console.log(error);
      }
    }
  },

  async mounted() {
    await this.buscarEmpresasReservantes();
    
    if (this.dados && this.dados.editar) {
      const empresaReservante = this.empresasReservantes.filter((e) => e.idempresareservante === this.dados.idempresareservante);
      this.canalDeVenda = empresaReservante[0];
      this.tituloContrato = this.dados.titulo;
      this.content = this.dados.contrato;
      this.update = true;
      this.msgBotaoCadastrarAtualizar = 'Atualizar Contrato';
      this.tituloModal = 'Editar/Visualizar Contrato'
    } else {
      this.canalDeVenda = null;
      this.tituloContrato = null;
      this.content = null;
      this.update = false;
      this.msgBotaoCadastrarAtualizar = 'Cadastrar Contrato';
      this.tituloModal = 'Cadastrar Contrato'
    }
  }
 }
</script>

<style scoped>
.buttonCadastrar {
  background-color: #1D4596;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0 0 0;
}
</style>