<template>
 <v-dialog v-model="exibirContrato" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Contrato Assinado
                    </span>
                    <div style="margin-right:50px;">
                        <v-btn  @click="imprimir">
                            <!-- <v-icon color="primary">mdi-download</v-icon> -->
                            <b>Imprimir</b>
                            <!-- <v-icon color="red">mdi-file-pdf</v-icon> -->
                        </v-btn>
                    </div>
                    <span style="width:5%;"> 
                        <v-btn x-small color="red" @click="() => this.$emit('fecharExibirContrato')"  title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>                      
            <div style="padding: 30px" v-html="contrato" id="contrato">
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
  export default {
    props: ['exibirContrato', 'urlDownloadContrato'],
    data() {
      return {
        contrato: '',
      }
    },
    methods: {
      async buscarContrato() {
        try {
          const result = await this.$http.post('/contrato/buscarcontratoassinado', {
            idpessoa: localStorage.getItem('idpessoa')
          });
          this.contrato = result.data[0].contrato;
          console.log(this.contrato)
        } catch (error) {
          console.log('erro');
        }
      },
      imprimir() {
        var contratoElement = document.getElementById("contrato");
        var clonedContract = contratoElement.cloneNode(true);

        // Remover o ID do elemento clonado para evitar conflitos
        clonedContract.removeAttribute("id");

        var newWin = window.open("", "", "height=800, width=800");
        newWin.document.write("<html><head><title>Economy Software</title>");

        // Copiar os estilos inline do elemento "contrato"
        var styleElements = contratoElement.querySelectorAll("style");
        styleElements.forEach(function (styleElement) {
          newWin.document.head.appendChild(styleElement.cloneNode(true));
        });

        newWin.document.write("</head><body>");
        newWin.document.write('<h1>Imprimir:</h1>');
        newWin.document.body.appendChild(clonedContract);
        newWin.document.write("</body></html>");
        newWin.document.close();
        newWin.print();
      },
      save() {
        window.open(this.urlDownloadContrato, '_blank')
      }
    },
    async mounted() {
      await this.buscarContrato();
    }
  }
</script>