var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000","max-width":"80vw"},model:{value:(_vm.exibirModalSolicitacoes),callback:function ($$v) {_vm.exibirModalSolicitacoes=$$v},expression:"exibirModalSolicitacoes"}},[_c('v-card',{staticStyle:{"max-width":"100%"}},[_c('v-toolbar',{staticClass:"mb-8",attrs:{"color":"blue"}},[_c('v-flex',{staticClass:"pa-2",staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"width":"95%","color":"white","font-weight":"bold"}},[_vm._v(" Solicitações ")]),_c('span',{staticStyle:{"width":"5%"}},[_c('v-btn',{attrs:{"x-small":"","color":"red","title":"Fechar"},on:{"click":function($event){return _vm.$emit('setModalSolicitacoes',false)}}},[_c('v-icon',{staticStyle:{"color":"white"},attrs:{"x-small":""}},[_vm._v("mdi-close")])],1)],1)])],1),_c('v-card-text',[_c('v-btn',{staticStyle:{"margin-bottom":"20px","font-weight":"bold"},attrs:{"medium":"","elevation":"","color":"info"},on:{"click":function($event){return _vm.setModalHistoricoSolicitacoes()}}},[_vm._v("Ver Histórico Atendimentos")]),[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Observações Webcheckin")]),_c('v-tab',[_vm._v("Pergunta Extra")]),_c('v-tab',[_vm._v("Horário do Voo")])],1)],_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"min-height":"290px"}},[_c('div',{staticStyle:{"padding":"1.0rem"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.solicitacoes,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reservahospede",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"color":"white","font-weight":"bold","padding-top":"1px","padding-bottom":"1px"},attrs:{"color":"blue","title":"Fechar"},on:{"click":function($event){return _vm.setExibirModalResponder(item)}}},[_c('v-icon',{staticStyle:{"color":"white","margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-account-arrow-left")]),_vm._v(" Responder ")],1)]}},{key:"item.atendida",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.atendida? 'blue': 'red',"dark":""}},[_vm._v(" "+_vm._s(item.atendida? 'Respondida': 'Não Respondida')+" ")])]}}],null,true)})],1)],1),_c('v-tab-item',[_c('div',{staticClass:"flex-row justify-center"},[_c('v-card',{attrs:{"min-height":"290px"}},[_c('div',{staticStyle:{"padding":"1.0rem"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersRespostas,"items":_vm.respostasExtras,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reservahospede",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"color":"white","font-weight":"bold","padding-top":"1px","padding-bottom":"1px"},attrs:{"color":"blue","title":"Fechar"},on:{"click":function($event){return _vm.setExibirModalResponder(item)}}},[_c('v-icon',{staticStyle:{"color":"white","margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-account-arrow-left")]),_vm._v(" Responder ")],1)]}},{key:"item.atendida",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.atendida? 'blue': 'red',"dark":""}},[_vm._v(" "+_vm._s(item.atendida? 'Respondida': 'Não Respondida')+" ")])]}}],null,true)})],1)],1)]),_c('v-tab-item',[_c('div',{staticClass:"flex-row justify-center"},[_c('v-card',{attrs:{"min-height":"290px"}},[_c('div',{staticStyle:{"padding":"1.0rem"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersVoo,"items":_vm.horarioVoo,"search":_vm.search},scopedSlots:_vm._u([{key:"item.reservahospede",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"color":"white","font-weight":"bold","padding-top":"1px","padding-bottom":"1px"},attrs:{"color":"blue","title":"Fechar"},on:{"click":function($event){return _vm.setExibirModalResponder(item)}}},[_c('v-icon',{staticStyle:{"color":"white","margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-account-arrow-left")]),_vm._v(" Responder ")],1)]}},{key:"item.atendida",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.atendida? 'blue': 'red',"dark":""}},[_vm._v(" "+_vm._s(item.atendida? 'Respondida': 'Não Respondida')+" ")])]}}],null,true)})],1)],1)])],1)],2)],1),(_vm.exibirModalHistoricoSolicitacoes)?_c('ModalHistoricoSolicitacoes',{attrs:{"exibirModalHistoricoSolicitacoes":_vm.exibirModalHistoricoSolicitacoes,"itemselecionado":_vm.itemselecionado,"flagatualizar":_vm.flagatualizar},on:{"setModalHistoricoSolicitacoes":function (valor) { return _vm.setModalHistoricoSolicitacoes(valor); }}}):_vm._e(),_c('ModalSolicitacoesResponder',{attrs:{"itemselecionado":_vm.itemselecionado,"exibirModalResponder":_vm.exibirModalResponder,"solicitacao":_vm.solicitacao},on:{"consultarSolicitacoes":function (valor) { return _vm.consultarSolicitacoes(valor); },"setModalResponder":function (valor) { return _vm.setModalResponder(valor); },"setFlagAtualizar":function () { return _vm.setFlagAtualizar(); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }