<template>
    <div>
        <div class="topoConfirmacao">
                <div class="topoCampoConfirmacao">
                    <!-- <img src="@/assets/img/logowc.png" class="topoImglogo" alt="WEB CHECK-IN" crossorigin /> -->
                    <img v-if="imgSrc" :src="'data:' + imgTipo + ';base64,' + imgSrc" class="topoImglogo" alt="WEB CHECK-IN" crossorigin />
                </div>

                <div class="topoCampoConfirmacao">
                    <img src="@/assets/img/logowc.png" class="topoImglogo" alt="WEB CHECK-IN" crossorigin />
                    <!-- <img v-if="this.$store.state.img_hotel" :src="this.$store.state.img_hotel" class="topoImglogo" alt="HOTEL LOGO" crossorigin /> -->
                </div>
        </div>

        <div class="captura_container" >
            
            <h2>Confirme seus Dados</h2>
            <div class="captura_container_confirmacao_dados">
                <div class="boxDadosConfirmacao" style="">
                    <label class="lblConfirmacaoDados" >Reserva</label>
                    <div class="textoConfirmacaoDados">{{dados.numeroreserva}}</div>
                </div>

                <div class="boxDadosConfirmacao" >
                    <label class="lblConfirmacaoDados" >Nome / Name</label>
                    <div class="textoConfirmacaoDados">{{dados.nome+' '+dados.sobrenome}}</div>
                </div>

                <div class="boxDadosConfirmacao" >
                    <label class="lblConfirmacaoDados" >Celular/ Phone</label>
                    <div class="textoConfirmacaoDados">{{"+"+dados.celularddi+" ("+dados.celularddd+") "+dados.celular}}</div>
                </div>

                <div class="boxDadosConfirmacao" >
                    <label class="lblConfirmacaoDados" >Email</label>
                    <div class="textoConfirmacaoDados">{{dados.email}}</div>
                </div>

                <div class="boxDadosConfirmacao" >
                    <label class="lblConfirmacaoDados" >Data Checkin / Checkin Date</label>
                    <div class="textoConfirmacaoDados">{{dados.datacheckinprevisto | formatarData}}</div>
                </div>

                <div class="boxDadosConfirmacao" >
                    <label class="lblConfirmacaoDados" >Endereço / Address:</label>
                    <div class="textoConfirmacaoDados">{{dados.endereco}}, {{dados.numero}}</div>
                </div>

                <div class="boxDadosConfirmacao" >
                    <label class="lblConfirmacaoDados" >Bairro / Neighborhood</label>
                    <div class="textoConfirmacaoDados">{{dados.bairro}}</div>
                </div>

                <div class="boxDadosConfirmacao" >
                    <label class="lblConfirmacaoDados" >Cidade / City</label>
                    <div class="textoConfirmacaoDados">{{dados.cidade}}</div>
                </div>

                <div class="boxDadosConfirmacao" >
                    <label class="lblConfirmacaoDados" >Pais / Country</label>
                    <div class="textoConfirmacaoDados">{{dados.pais}}</div>
                </div>


            </div>

            

            <div class="cardInputs" style="width:80vw;max-width: 500px;display:flex;flex-direction:column;padding-top:0px;padding-bottom:0px;margin-top:00px;width:100%">

                <div style="width:100%;">
                    <div class="cardInputs" style="width:80vw;max-width: 500px;display:flex;flex-direction:row;padding-top:15px;padding-bottom:0px;margin-top:10px;width:100%">

                        <!-- <input type="checkbox" id="aceitetermo" name="aceitetermo"
                            v-model="lgpd" true-value="true" false-value="false" 
                            style="margin-left:10px;margin-right:0px;-ms-transform: scale(2);-webkit-transform: scale(2);-o-transform: scale(2);transform: scale(2);">
                        <label class="containerCheck" style="margin-left:20px;min-width:100%;margin-top:20px;font-size:18px;margin-bottom:10px;">
                            <span class="checkmarkCheck" ></span>Aceito Termos da LGPD
                        </label>
 -->

                        <label class="containerCheck" style="min-width:100%;margin-top:20px;font-size:18px;margin-bottom:10px;">
                            <input type="checkbox" id="aceitetermo" name="aceitetermo"  
                                v-model="lgpd" true-value="true" false-value="false" 
                                style="margin-left:10px;margin-right:20px;-ms-transform: scale(2);-webkit-transform: scale(2);-o-transform: scale(2);transform: scale(2);">
                            <span class="checkmarkCheck" ></span>Aceito Termos da LGPD-
                        </label>

                    </div>
                    <a  target="blank_" style="text-decoration:underline!important;font-size:18px;margin-left:10px;margin-top:20px!important;" @click="exibirModalTermoLGPD = true" >
                            Ver Termo LGPD
                    </a>
                </div>

                <label class="containerCheck" style="min-width:100%;margin-top:20px;font-size:18px;margin-bottom:10px;">
                    <input type="checkbox" id="aceitetermo" name="aceitetermo"  
                        v-model="aceitetermo" true-value="sim" false-value="nao" 
                        style="margin-left:10px;margin-right:20px;-ms-transform: scale(2);-webkit-transform: scale(2);-o-transform: scale(2);transform: scale(2);">
                    <span class="checkmarkCheck" ></span>Li e Aceito os Termos
                </label>

                 <a  target="blank_" style="text-decoration:underline!important;font-size:18px;margin-left:10px;" @click="exibirModalTermo = true" >
                    Ver Termo de Responsabilidade
                </a>

            </div>

            <v-dialog v-model="exibirModalTermo" width="90%"  style="min-height:90vh;">
                <v-card style="min-height:90vh;">
                    
                <v-toolbar color="blue" class="mb-8">
                        <v-flex  style="display: flex" class="pa-2">
                            <span style="width:95%;color:white;font-weight:bold;" >
                                Termo
                            </span>
                            <span style="width:5%;padding-right:30px;"> 
                                <v-btn x-small color="red" @click="exibirModalTermo = false" title="Fechar">
                                    <v-icon x-small style="color:white;">mdi-close</v-icon>
                                </v-btn>
                            </span>
                        </v-flex>
                    </v-toolbar>
        
                    
                    <div style="display:flex;align-items:center;flex-direction:column;">
                        <div style="margin-bottom:20px;"> 
                                <v-btn @click="()=>{ this.pagina > 1 ? this.pagina -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                                <span style="font-size:20px;">Pagina {{pagina}}</span>
                                <v-btn @click="()=>{ this.pagina += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                            
                        </div>
                        <pdf :src="termoSrc" style="width:80%!important;"
                            :page="pagina" >
                        </pdf>
                        <div style="margin-bottom:20px;margin-top:20px;"> 
                                <v-btn @click="()=>{ this.pagina > 1 ? this.pagina -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                                <span style="font-size:20px;">Pagina {{pagina}}</span>
                                <v-btn @click="()=>{ this.pagina += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                            
                        </div>
                    </div>
                    
                    
                </v-card>
            </v-dialog>

            <v-dialog v-model="exibirModalTermoLGPD" width="90%"  style="min-height:90vh;">
                <v-card style="min-height:90vh;">
                    
                <v-toolbar color="blue" class="mb-8">
                        <v-flex  style="display: flex" class="pa-2">
                            <span style="width:95%;color:white;font-weight:bold;" >
                                Termo LGPD
                            </span>
                            <span style="width:5%;padding-right:30px;"> 
                                <v-btn x-small color="red" @click="exibirModalTermoLGPD = false" title="Fechar">
                                    <v-icon x-small style="color:white;">mdi-close</v-icon>
                                </v-btn>
                            </span>
                        </v-flex>
                    </v-toolbar>
        
                    
                    <div style="display:flex;align-items:center;flex-direction:column;">
                        <div style="margin-bottom:20px;"> 
                                <v-btn @click="()=>{ this.paginaLGPD > 1 ? this.paginaLGPD -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                                <span style="font-size:20px;">pagina {{paginaLGPD}}</span>
                                <v-btn @click="()=>{ this.paginaLGPD += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                            
                        </div>
                        <pdf :src="termoLGPDSrc" style="width:80%!important;"
                            :page="paginaLGPD" >
                        </pdf>
                        <div style="margin-bottom:20px;margin-top:20px;"> 
                                <v-btn @click="()=>{ this.paginaLGPD > 1 ? this.paginaLGPD -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                                <span style="font-size:20px;">pagina {{paginaLGPD}}</span>
                                <v-btn @click="()=>{ this.paginaLGPD += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                            
                        </div>
                    </div>
                    
                    
                </v-card>
            </v-dialog>

            <button @click="setarPasso(5)"  type="button" class="botaoAzul">Confirmar</button>
        </div>
    </div>
</template>



<script>
import Vue from 'vue'; 
import pdf from 'vue-pdf'


export default {
  data: () => ({
    image: '',
    lgpd:false,
    temtermo:null,
    temtermoLGPG:null,
    exibirModalTermo:false,
    exibirModalTermoLGPD:false,
    pagina:1,
    paginaLGPD:1,
    arquivo_termo:null,
    aceitetermo:null,
    id_termo:null,
    id_termoLGPD:null,
    termoSrc:null,
    termoLGPDSrc:null,
    termoTipo:null,
    termoLGPDTipo:null,
  }),
  components: {
		pdf: pdf
	},
  watch: {
    dados(val) {
      this.dados = val;
    },
  },
  props:["idHospede","idReserva","idReservaHospede","dados"],
  methods: {
    setarPasso(valor){


        this.lgpd = this.lgpd === 'true' || this.lgpd ? true : false


        if(this.lgpd){

            if(this.temtermo == 's' && (this.aceitetermo == "nao" || this.aceitetermo == undefined || this.aceitetermo == "" || this.aceitetermo == null)){ 
                alert("A aceitação do Termo de Responsabilidade é obrigatória!")    
            }else{
                
                // this.$emit('setarPasso',valor)
  
                let dadosUpdate = {
                        reserva:{
                                id:this.dados.idreserva,
                                hotel:this.dados.hotel
                            },
                        hospede:{
                                id:this.dados.idhospede,
                                idhospedepms:this.dados.idhospedepms,
                                termolgpd:true,
                                aceitetermo:true
                            }
                    }
                console.log(dadosUpdate)
                this.$http.post("/reserva/upinsert", dadosUpdate)
                .then(async () =>{
                    
                    let perguntasok = await this.verificarPerguntas()
                    if (perguntasok) {
                        this.$emit('setarPasso',valor)
                    } else {                        
                        // Se não houver perguntas, pular o passo do formulário...
                        this.$emit('setarPasso',6)
                    }
                        
                }).catch(error =>{
                    alert(error)
                    console.log(error)
                })
            }
            
        }else{
            alert("A aceitação do Termo LGPD é obrigatória!")
        }
    },        
    async verificarPerguntas(){
        this.$http.post("formulario/select",{id:1}).then(async resp =>{

            let result = true;
                
            if (!resp.data[0]||!resp.data[0].perguntas) result = false;

            return result;

        })
    },
  },
  async mounted() {

            await this.$http.post("configuracao/buscarParametro", {  "idhotel" : this.dados.hotel,"chave" : 'WCHECKINESTILO'})
                  .then(async resp =>{
                      await import('../../webcheckin/folhadeestilos/'+resp.data[0].valor.toLowerCase()+'.css')
                        Vue.forceUpdate();
                  }).catch(error =>{ console.log(error) })
            // Vue.forceUpdate();
     
            this.$http.post("perfil/hotel/select",{id:this.dados.hotel})
            .then(resp => {
                   

                    if(resp.data[0].termo){
                       
                        
                        this.id_termo = resp.data[0].termo
                        if(this.id_termo){
                            this.temtermo = 's'
                        }else{
                            console.log("CC")
                        }

                        this.$http.post("/imagem/select", {id:this.id_termo}, { timeout: 60000 })
                                    .then((response) => {
                                        console.log("response")
                                        console.log(response)
                                        const uint8Array = new Uint8Array(response.data[0].dados.data);
                                        console.log("A 2")
                                        this.termoSrc = uint8Array.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');
                                        console.log("A 3")
                                        this.termoTipo = response.data[0].tipo;
                                        console.log("A 4")

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });

                    }

                    if(resp.data[0].termolgpd){
                       
                        
                        this.id_termoLGPD = resp.data[0].termolgpd
                        if(this.id_termoLGPD){
                            this.temtermoLGPG = 's'
                        }else{
                            console.log("CC")
                        }

                        this.$http.post("/imagem/select", {id:this.id_termoLGPD}, { timeout: 60000 })
                                    .then((response) => {
                                        console.log("response")
                                        console.log(response)
                                        const uint8Array = new Uint8Array(response.data[0].dados.data);
                                        console.log("A 2")
                                        this.termoLGPDSrc = uint8Array.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');
                                        console.log("A 3")
                                        this.termoLGPDTipo = response.data[0].tipo;
                                        console.log("A 4")

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });

                    }

            })
            .catch(error => {
                    console.log(error)
            })
  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .boxDadosConfirmacao{
        border-bottom:0.5px solid #ababab;
        width:100%;
        margin-bottom:5px;
        /* display:flex;
        flex-wrap: wrap; */
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:red; */
        width:100vw;
        min-height:75vh;
        max-height:100vh;
        display:flex;
        flex-direction: column;
        /* justify-content: center; */
        /* align-items: center; */
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
        padding-top:20px;
        padding-bottom:5px;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .captura_container_confirmacao_dados{
        /* background-color:#b82323; */
        border-radius:5px;
        width:80vw;
        min-width: 80vw;
        max-width: 90vw;
        min-height:60vh;
        max-height: 70vh;
        padding:5%;
        display:flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content:flex-start;
        overflow: auto;
    }

    .lblConfirmacaoDados{
        font-weight:bold;
        font-size:16px!important;
        color:#1560E5;
        font-family:Arial, Helvetica, sans-serif;
    }

    .textoConfirmacaoDados{
        padding-left:10px;
        font-size:16px;
        font-family:Arial, Helvetica, sans-serif;
        font-weight: normal;
        margin-bottom:10px;
        color:#2c2c2c!important;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoAzul{
        background-color:#1560e5;
        height:50px!important;
        min-height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>